import * as React from 'react';
import { PassedState } from '../../state';
import { specialties, specialtyData, toCamelCase } from '../../state/data';
import ChargeLag from '../Calculations/ChargeLag';
import ChargeVolumeImproved from '../Calculations/ChargeVolumeImproved';
import ChargeVolumeImprovedNew from '../Calculations/ChargeVolumeImprovedNew';
import Mips from '../Calculations/Mips';
import PerChargeImproved from '../Calculations/PerChargeImproved';
import PerChargeImprovedNew from '../Calculations/PerChargeImprovedNew';
import RevenueCurrent from '../Calculations/RevenueCurrent';
import RevenueImproved from '../Calculations/RevenueImproved';
import RevenueImprovedAdditional from '../Calculations/RevenueImprovedAdditional';
import RevenueImprovedNet from '../Calculations/RevenueImprovedNet';
import RevenueImprovedNetPlus from '../Calculations/RevenueImprovedNetPlus';
import RevenueImprovedNetPerFTE from '../Calculations/RevenueImprovedNetPerFTE';
import RevenueImprovedNetPerProvider from '../Calculations/RevenueImprovedNetPerProvider';

// Material UI
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/core/styles';


//Images
import Image_005 from './Image_005.png';
import Image_006 from './Image_006.png';
import Image_007 from './Image_007.png';
import Image_008 from './Image_008.png';
import Image_009 from './Image_009.png';
import Image_010 from './image2vector.svg';

export interface specialtyMap {
    [key: string]: any;
}

export default class PdfReportContent extends React.Component<PassedState> {
    // retrieve original name of specialty
    specialtyMapping: specialtyMap = {};
    constructor( props: PassedState ) {
        super( props );
        specialties.forEach(specialty => {
            const key = toCamelCase( specialty);
            this.specialtyMapping[key] = specialty;
        });
    }
    
    
    // fix wkhtmltopdf bug https://github.com/wkhtmltopdf/wkhtmltopdf/issues/2469
    tdStyle = {
        borderBottom: '1px solid #f2f2f2'
    };

    firstTr = {
        marginBottom: '20px'
    };

    Improvement = {
        backgroundColor:"#D1252E",
        color: "#FFFFFF", 
        display:"flex",
        justifyContent: "center",
        alignContent: "center",
        alignItems:"center" 
    };

    Inputs = {
        backgroundColor: "#EBEBEB",
        color: "#7F7F7F",
    };



    theme = createTheme({
        palette: {
          primary: {
            // Purple and green play nicely together.
            main: '#11cb5f',
          },
          secondary: {
            // This is green.A700 as hex.
            main: '#11cb5f',
          },
        },
      });

    render() {
        return (
            <div id="pdf-report-content">
            <div className="report-content">
                <div className="page page-1">
                    <h2>Introduction</h2>
                    <p>Financial success depends on an efficient and effective revenue cycle management (RCM) function built on powerful charge capture, accurate coding, and swift billing.</p>
                    <p>Enter just four data points in the Revenue Optimization Calculator to learn how much your organization can increase its revenue using Ingenious Med. The calculator uses proprietary and third-party data to determine your potential improvement in revenue collections, average charges per patient, average payments per billed encounter, and more.</p>
                    <p>See your customized results and a detailed explanation of the calculator methodology below.</p>
                    <table className="table">
                        <tr>
                            <td className="pdf-sidebar">
                                <h2 className="sidebar-title">Inputs</h2>
                                <div className="sidebar-content">
                                    <div className="sidebar-hr">
                                        <p className="pdf-label">Number of physicians<br />in your group</p>
                                        <p className="pdf-value">{this.props.state.providers}</p>
                                    </div>
                                    <div>
                                        <p className="pdf-label">Annual number of<br />charges (Group)</p>
                                        <p className="pdf-value">{this.props.state.annualCharges}</p>
                                    </div>
                                    <div>
                                        <p className="pdf-label">Average dollars<br />collected per charge</p>
                                        <p className="pdf-value">{this.props.state.perCharge}</p>
                                    </div>
                                    <div>
                                        <p className="pdf-label">Current annual<br />revenue collected</p>
                                        <p className="pdf-value"><RevenueCurrent state={this.props.state} /></p>
                                    </div>
                                </div>
                            </td>
                            <td className="pdf-content">
                                <h2 className="improvement-title">Improvement</h2>
                                <div className="improvement-content">
                                    <p className="improvement-projected">
                                        <span>
                                            Projected Annual<br />
                                            Revenue Increase
                                        </span><br />
                                        <span className="projected-value">
                                            <RevenueImprovedNet state={this.props.state} />
                                        </span>
                                    </p>
                                    <p className="improvement-physician">
                                        <span>
                                            Projected Annual Revenue<br />
                                            Per Physician Increase
                                        </span><br />
                                        <span className="physician-value">
                                            <RevenueImprovedNetPerProvider state={this.props.state} />
                                        </span>
                                    </p>
                                    <p className="improvement-current">
                                        <span>Current Annual Revenue </span><b><RevenueCurrent state={this.props.state} /></b>
                                    </p>
                                    <p className="improvement-primary">
                                        <span>Primary Beneﬁts</span>
                                    </p>
                                    <p className="improvement-potential">
                                        <span>Potential Beneﬁts</span>
                                    </p>
                                    <p className="primary-value">
                                        <span><RevenueImprovedNetPlus state={this.props.state} /></span>
                                    </p>
                                    <p className="potential-value">
                                        <span><Mips  state={this.props.state} /></span>
                                    </p>
                                    <p className="potential-Revenue">
                                        <i>Potential Avenue Revenue Increase</i>
                                        <b><RevenueImprovedAdditional  state={this.props.state} /></b>
                                    </p>
                                </div>
                                <div className="space-150"></div>
                            </td>
                        </tr>
                    </table>
                </div>
                <div className="page page-2">
                    <div className="primary-beneﬁts">
                        <div className="section group">
                            <div className="beneﬁts-content col">
                                <h2>Primary Beneﬁts</h2>
                                <p>Using Ingenious Med makes it easy for your providers to capture charges at the point of care and for billing/coding staff to identify and fix missing bills. </p>
                                
                                <p>The calculator's revenue increases are based on actual results from over 1,000 physician clients, who have experienced:</p>                        
                                <p>
                                    <ul>
                                        <li>An average of {this.props.state.specialty
                                                            ? specialtyData[this.props.state.specialty].changeVolume *
                                                                100
                                                            : 0}% increase in charges per physician.</li>
                                        <li>And a {this.props.state.specialty
                                                                ? specialtyData[this.props.state.specialty]
                                                                        .changePerCharge * 100
                                                                : 0}% increase in dollars collected per charge</li>
                                    </ul>
                                </p>                    
                            </div>
                            <div className="beneﬁts-сharts col">
                                <div className="сharts" style={{marginBottom: '20px'}}>
                                    <img src="https://calculator.ingeniousmed.com/assets/primary.png" height="50" /><ChargeVolumeImprovedNew state={this.props.state} />
                                    <p style={{padding: '0px', margin: '0px', fontWeight: 'normal'}}>Number of Charges</p>
                                </div>
                                <div className="сharts">
                                    <img src="https://calculator.ingeniousmed.com/assets/dollar.png" height="50" /><PerChargeImprovedNew state={this.props.state} /><br />
                                    <p style={{padding: '0px', margin: '0px', fontWeight: 'normal'}}>Dollars per Charge</p>
                                </div>                            
                            </div>
                        </div>
                    </div>
                    <div className="space-200"></div>
                    <div className="additional-potential">
                        <h2>Additional Potential Beneﬁts</h2>
                        <p>In addition to the potential revenue gains described above, Ingenious Med customers have also reported improvements in many other areas of their revenue cycle, including:</p>
                        
                        <div className="additional-icon">
                            <img className="center" src="https://calculator.ingeniousmed.com/assets/additional.png" />
                        </div>
                    </div>
                </div>
                <div className="page page-3 page-title-blue section group">
                    <div className="col">
                        <h2>MIPS Reporting</h2>
                        <p>For the 2020 participation period and beyond, MIPS (Merit-Based Incentive Payment System) represents a potential positive or negative 9% payment adjustment to all Medicare Part B reimbursements.</p>
                        <p>According to 2013 MGMA data, a typical hospitalist represents a potential additional $18,191 in combined penalty avoidance and incentive earnings.</p>

                        <br /><br />
                        <h2>Charge Lag</h2>
                        <p>Ingenious Med customers experience a median 14-day decrease in charge lag. In addition to operational eﬃciency improvements, this decrease represents dollar savings for the organization. Based on the average daily collections from Ingenious Med customers of $372 per physician and an 8% cost of capital, this improvement can result in a $417 savings per physician per year in ﬁnancing costs. For every 10 doctors, this improvement in charge lag can free up $52,000 in cash and save $4,100 in ﬁnancing costs annually.</p>
                    </div>
                    <div className="col">
                        <h2>Collection rate and denials</h2>
                        <p>Ingenious Med customers have experienced higher collection rates due to faster and more accurate charge capture. Faster charge submissions have led to higher reimbursement amounts. Capturing charges at the point of care and using built-in tools like MasterCoder have reduced under and over-coding claims, leading to fewer denials.</p>
                        <br />
                        <h2>Practice efficiency</h2>
                        <p>Your practice can significantly increase its revenue cycle efficiency because Ingenious Med enables billing and coding staff to spend less time following up with physicians and manually entering charges, and more time on strategic tasks.</p>
                        <p>Furthermore, automatic reporting and dashboards can highlight lower performing users, ensuring smoother retraining programs.</p>
                        <br />
                        <h2>Compliance</h2>
                        <p>Ingenious Med tools like MasterCoder and Document Image Capture help organizations ensure more accurate coding compliance and capture clinical documentation. Charge note reconciliation ensures that administrators can see quickly that documentation may be missing or incomplete for speciﬁc charges. </p>
                        <p>This additional compliance feature can reduce the likelihood of audits and costly compliance fines.</p>
                    </div>
                </div>
                <div className="page page-4">
                    <div className="methodology">
                        <h2>Methodology</h2>
                        <p>Ingenious Med has combined third-party research, CMS payment ﬁles for millions of physician services claims, and over 30 quantitative net revenue improvement studies of our customers to build the calculator tool.</p>
                        <p>These quantitative studies look at two critical drivers of professional fee revenue: the average number of charges per patient and the average dollars per charge. Ingenious Med has developed a standardized process to measure these impacts, comparing the customer’s billing data before implementation and three to four months after. The analysis takes census ﬂuctuations into consideration to normalize charge volume. It also compares billing data year over year to account for seasonal variations in procedures, fee schedules, and case mix index.</p>
                        <p>Based on these sources, we used payment data for dozens of primary care and specialty practices to build the model.</p>
                        <p>For a more detailed look at this methodology, please view our <a href="https://calculator.ingeniousmed.com/methodology.html">Methodology document.</a></p>
                    </div>
                   
                    <div className="conclusion section group">
                        <div className="col">
                            <h2>Conclusion</h2>
                            <p>Your organization can't afford to missing or delayed charge capture. Ingenious Med's rigorous research and customer results have repeatedly proven its ability to significantly increase total revenues, charge volumes, and dollars per charge.</p>
                            <p>To ﬁnd out more about Ingenious Med, please request a meeting with an Ingenious Med representative or call us at <a href="tel:770-799-0909">770-799-0909</a>.</p>
                        </div>
                        <div className="col">
                            <img className="center" src='https://calculator.ingeniousmed.com/assets/mobile.png' />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        );
    }
}
